var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-1","size":"lg","title":"Tambah Paket Sub Kegiatan","ok-only":"","ok-title":"Accept","hide-footer":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Paket Sub Kegiatan/Projek"}},[_c('validation-provider',{attrs:{"name":"SKPD","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_paket","options":_vm.daftar_paket,"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.load_detail_paket()}},model:{value:(_vm.input.id_paket),callback:function ($$v) {_vm.$set(_vm.input, "id_paket", $$v)},expression:"input.id_paket"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.input.id_paket != null ),expression:"input.id_paket != null "}]},[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('h6',[_vm._v("Kede OPD "),_c('strong',[_vm._v(_vm._s(_vm.detail_paket.kode_dinas)+" ")])]),_c('small',[_vm._v("Nama OPD")]),_c('h6',[_vm._v(_vm._s(_vm.detail_paket.nama_dinas)+" ")]),_c('small',[_vm._v("Nama Sub Kegiatan ")]),_c('h6',[_c('strong',[_vm._v(_vm._s(_vm.detail_paket.kode_sub_kegiatan)+" - "+_vm._s(_vm.detail_paket.nama_sub_kegiatan)+" ")])]),_c('small',[_vm._v("Lokasi Sub Kegiatan ")]),_c('h6',[_c('strong',[_vm._v(_vm._s(_vm.detail_paket.nm_wil)+" ")])]),_c('small',[_vm._v("besaran Anggaran ")]),_c('h4',[_c('sup',[_vm._v("Rp. ")]),_c('strong',[_vm._v(_vm._s(_vm.Rp(_vm.detail_paket.anggaran))+",-")])])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Nomor Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.input.no_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "no_kontrak", $$v)},expression:"input.no_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "tgl_kontrak", $$v)},expression:"input.tgl_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Nama Rekanan"}},[_c('validation-provider',{attrs:{"name":"Rekanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_rekanan","options":_vm.rekanan,"state":errors.length > 0 ? false:null},model:{value:(_vm.input.id_rekanan),callback:function ($$v) {_vm.$set(_vm.input, "id_rekanan", $$v)},expression:"input.id_rekanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nilai Projek","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Anggaran ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CurrencyInput',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.input.nilai_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "nilai_kontrak", $$v)},expression:"input.nilai_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"tanggal Mulai Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker2","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_mulai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_mulai", $$v)},expression:"input.tgl_mulai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Selesai Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker3","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_selesai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_selesai", $$v)},expression:"input.tgl_selesai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"basicInput"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Keterangan","rows":"3"},model:{value:(_vm.input.keterangan),callback:function ($$v) {_vm.$set(_vm.input, "keterangan", $$v)},expression:"input.keterangan"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Simpan")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"warning"}},[_vm._v(" Batal")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }