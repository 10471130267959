<template>
  <div>
    <b-card class="bg-light-primary">
      <b-row style="margin-top :-10px; margin-bottom :-20px">
        <b-col md="9">
          <b-form-group>
            <v-select v-model="id_dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
              @input="loadData()" :options="opd" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-button block variant="primary" v-b-modal.modal-1>
            <feather-icon size="15" icon="PlusIcon" /> Tambah Kontrak
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <daftar-paket :items=items @detailData=detailData @hapus="hapus"></daftar-paket>
    </b-card>
    <tambah-paket :daftar_paket=daftar_paket :rekanan=rekanan @insertPaket=insertPaket></tambah-paket>
    <detai-kegiatan :rekanan=rekanan :detail=detail @updateData="updateData"></detai-kegiatan>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DaftarPaket from './componet/DaftarPaket.vue'
import TambahPaket from './componet/TambahPaket.vue'
import DetaiKegiatan from './componet/DetailKegiatan.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
    DaftarPaket,
    TambahPaket,
    DetaiKegiatan,
  },
  mixins: [Base],
  data() {
    return {
      opd: [],
      id_master_kode_dinas: "",
      id_dinas: {},
      items: [],
      daftar_paket: [],
      rekanan: [],
      detail: {}
    }
  },
  mounted() {
    this.daftar_opd();
    this.load();
    this.load_daftar_paket();
    this.load_rekanan();
  },
  methods: {
    detailData(val) {
      this.detail = val
    },
    loadData() {
      this.id_master_kode_dinas = this.id_dinas.id_master_kode_dinas;
      this.load();
    },
    async insertPaket(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/input/data_kontrak/insert',
        data: {
          id_paket: value.id_paket.id_paket,
          no_kontrak: value.no_kontrak,
          tgl_kontrak: value.tgl_kontrak,
          id_rekanan: value.id_rekanan.id,
          nilai_kontrak: value.nilai_kontrak,
          tgl_mulai: value.tgl_mulai,
          tgl_selesai: value.tgl_selesai,
          keterangan: value.keterangan,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load()
          self.notification('info', "info", response.data.message);
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async updateData(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/input/data_kontrak/update',
        data: {
          id: input.id,
          no_kontrak: input.no_kontrak,
          tgl_kontrak: input.tgl_kontraks,
          id_rekanan: input.rekanan.id,
          nilai_kontrak: input.nilai_kontrak,
          tgl_mulai: input.tgl_mulai_kontrak,
          tgl_selesai: input.tgl_selesai_kontrak,
          keterangan: input.keterangan,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.load()
          self.notification('info', "info", response.data.message);
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async hapus(val) {
      if (confirm('apakah anda yakin akan menghapus data ini') == true) {
        const self = this;
        await axios({
          method: 'POSt',
          url: '/api/input/data_kontrak/hapus_data',
          data: {
            id: val
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.load()
            self.notification('info', "info", response.data.message);

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    async load_daftar_paket() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_kontrak/daftar_paket',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.daftar_paket = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_rekanan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_kontrak/rekanan',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rekanan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_kontrak/load',
        data: {
          id_master_kode_dinas: self.id_master_kode_dinas
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async daftar_opd() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/input/data_paket/daftar_opd',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.opd = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>