<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-1" size="lg" title="Tambah Paket Sub Kegiatan" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop>
            <validation-observer ref="simpleRules">
                <b-row> 
                    <b-col md="12">
                        <b-form-group label="Nama Paket Sub Kegiatan/Projek">
                            <validation-provider #default="{ errors }" name="SKPD" rules="required">
                                <v-select v-model="input.id_paket" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    @input="load_detail_paket()" label="nama_paket" :options="daftar_paket"
                                    :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-row v-show="input.id_paket != null ">
                            <b-col md="12"> 
                                <b-alert variant="primary" show>
                                    <div class="alert-body"> 
                                        <h6>Kede OPD <strong>{{detail_paket.kode_dinas}} </strong></h6>
                                        <small>Nama OPD</small>
                                        <h6>{{detail_paket.nama_dinas}} </h6>
                                        <small>Nama Sub Kegiatan </small>
                                        <h6><strong>{{detail_paket.kode_sub_kegiatan}} - {{detail_paket.nama_sub_kegiatan}}  </strong></h6>
                                        <small>Lokasi Sub Kegiatan </small>
                                        <h6><strong>{{detail_paket.nm_wil}} </strong></h6>
                                        <small>besaran Anggaran </small>
                                        <h4><sup>Rp. </sup><strong>{{Rp(detail_paket.anggaran)}},-</strong></h4>
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Nomor Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Nomor Kontrak" rules="required">
                                        <b-form-input v-model="input.no_kontrak"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Tanggal Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker" v-model="input.tgl_kontrak"
                                            class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>  
                        <b-form-group label="Nama Rekanan">
                            <validation-provider #default="{ errors }" name="Rekanan" rules="required">
                                <v-select v-model="input.id_rekanan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="nama_rekanan" :options="rekanan" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <!-- <b-form-group label="Nama Projek" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Nama Projen" rules="required">
                                <b-form-input v-model="input.nama_projek" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group> -->

                        <b-form-group label="Nilai Projek" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Anggaran " rules="required">
                                <CurrencyInput class="form-control" :state="errors.length > 0 ? false:null" v-model="input.nilai_kontrak" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="tanggal Mulai Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker2" v-model="input.tgl_mulai"
                                            class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Tanggal Selesai Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker3" v-model="input.tgl_selesai"
                                            class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false:null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Keterangan" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Keterangan" rows="3" v-model="input.keterangan" />
                        </b-form-group>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-button variant="primary" @click.prevent="validationForm"> Simpan</b-button>
                        <b-button variant="warning" class="ml-1"> Batal</b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base'
    import axios from '@/config/Axios'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations'

    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormTextarea,
        BCardText,
        BFormDatepicker
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    import CurrencyInput from './CurrencyInput.vue' 

    export default {
        components: {
            BButton,
            ValidationProvider,
            ValidationObserver,
            BModal,
            BAlert,
            BRow,
            BCol,
            vSelect,
            BFormGroup,
            BFormInput,
            BFormSelect,
            CurrencyInput,
            BFormTextarea,
            BCardText,
            BFormDatepicker,
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        mixins: [Base],
        props: {
            daftar_paket: {},
            rekanan: {}
        },
        emits: ['insertPaket'],
        data() {
            return {
                input: {},
                required,
                value: '',
                detail_paket: {} ,
                id_paket :''

            }
        },
        mounted() {

        },
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$root.$emit('bv::hide::modal', 'modal-1');
                        this.$emit("insertPaket", this.input);
                        this.input = {};
                    }
                })
            },
            async load_detail_paket() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/input/data_kontrak/detail_paket',
                        data: {
                            id_paket: self.input.id_paket.id_paket
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.detail_paket = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

        },
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>