<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(daftarPaket)="data">
                    <b-alert show variant="primary"
                        style="margin-left: -30px; margin-right: -20px;; margin-bottom: -10px;;">
                        <div class="alert-body">
                            <b-row>
                                <b-col md="4">
                                    <small><strong>{{ data.item.kode_dinas }} </strong><br>
                                        <h6>{{ data.item.nama_dinas }}</h6>
                                    </small>
                                    <small>Nama Sub Kegiatan </small>
                                    <h6> {{ data.item.kode_sub_kegiatan }} - {{ data.item.nama_sub_kegiatan }}</h6>
                                    <small>Lokasi Sub Kegiatan </small>
                                    <h6><small>{{ data.item.nm_wil }} </small></h6>
                                    <b-button block variant="warning" size="sm" v-if="data.item.st_pencairan == '1'">
                                        <feather-icon size="15" icon="BellIcon" /> Sudah Pencairan Kontrak
                                    </b-button>
                                </b-col>
                                <b-col md="5">
                                    <small>Nama Rekanan </small>
                                    <h6><strong>{{ data.item.nama_rekanan }} </strong><br>
                                        <small>Tgl Kontrak {{ data.item.tgl_kontrak }} </small>
                                    </h6>
                                    <small>Jangka Waku Kontrak kerja </small>
                                    <h6> {{ data.item.tgl_mulai }} Sampai Dengan {{ data.item.tgl_selesai }} </h6>
                                    <small><strong>Keterangan</strong> </small>
                                    <p style="text-align: justify;">
                                        <small>{{ data.item.keterangan }}</small>
                                    </p>

                                </b-col>
                                <b-col md="3">
                                    <h6><small>Type Kegiatan</small><br>Kegiatan {{ data.item.jenis_kegiatan }}</h6>
                                    <small>Nilai Kontrak</small>
                                    <h2 class="text-primary"> <sup>Rp.</sup> <strong>{{ Rp(data.item.nilai_kontrak) }}
                                        </strong><small> </small></h2>
                                    <small class="text-warning">Dari Nilai Pakte Sub Kegiatan</small>
                                    <h5 class="text-warning"> <sup>Rp.</sup> <strong>{{ Rp(data.item.anggaran) }}
                                        </strong><small> </small></h5>
                                    <b-button block variant="primary" size="sm" v-b-modal.modal-2
                                        v-if="data.item.st_pencairan == '0'" @click="detailData(data.item)">
                                        <feather-icon size="15" icon="EditIcon" /> Edit Kontrak
                                    </b-button>
                                    <b-button block variant="danger" size="sm" @click="hapus(data.item.id)"
                                        v-if="data.item.st_pencairan == '0'">
                                        <feather-icon icon="TrashIcon" size='15' /> Hapus Kontrak
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-alert>
                </template>
            </b-table>
        </b-col>
        <b-col cols="6">
            <strong>Total Row {{ totalRows }}</strong>
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import Base from '@/config/Mixins_base'
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton, BAlert
    },
    props: {
        items: {},
        rekanan: {}
    },
    mixins: [Base],
    data() {
        return {
            perPage: 5,
            pageOptions: [3, 5, 10],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [{
                key: 'daftarPaket',
                label: '',
                thStyle: {
                    display: 'none'
                }
            },],


        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length
    },
    emits: ['detailData', 'hapus'],
    methods: {
        hapus(val) {
            this.$emit('hapus', val)
        },
        detailData(val) {
            this.$emit('detailData', val)
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>