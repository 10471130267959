var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-2","size":"lg","title":"Tambah Paket Sub Kegiatan","ok-only":"","ok-title":"Accept","hide-footer":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('small',[_vm._v("Kede OPD ")]),_c('h6',[_c('strong',[_vm._v(_vm._s(_vm.detail.kode_dinas))])]),_c('small',[_vm._v("Nama OPD")]),_c('h6',[_vm._v(_vm._s(_vm.detail.nama_dinas))]),_c('small',[_vm._v("Nama Sub Kegiatan ")]),_c('h6',[_c('strong',[_vm._v(_vm._s(_vm.detail.kode_sub_kegiatan)+" "+_vm._s(_vm.detail.nama_sub_kegiatan))])]),_c('small',[_vm._v("Lokasi Sub Kegiatan ")]),_c('h6',[_c('strong',[_vm._v("Kecamatan "+_vm._s(_vm.detail.nm_wil))])]),_c('small',[_vm._v("besaran Anggaran ")]),_c('h4',[_c('sup',[_vm._v("Rp. ")]),_c('strong',[_vm._v(" "+_vm._s(_vm.Rp(_vm.detail.anggaran))+",-")])])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Nomor Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.no_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "no_kontrak", $$v)},expression:"input.no_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false : null},model:{value:(_vm.input.tgl_kontraks),callback:function ($$v) {_vm.$set(_vm.input, "tgl_kontraks", $$v)},expression:"input.tgl_kontraks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Nama Rekanan"}},[_c('validation-provider',{attrs:{"name":"Rekanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nama_rekanan","options":_vm.rekanan,"state":errors.length > 0 ? false : null},model:{value:(_vm.input.rekanan),callback:function ($$v) {_vm.$set(_vm.input, "rekanan", $$v)},expression:"input.rekanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nilai Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Anggaran ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CurrencyInput',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.input.nilai_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "nilai_kontrak", $$v)},expression:"input.nilai_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"tanggal Mulai Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker2","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false : null},model:{value:(_vm.input.tgl_mulai_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "tgl_mulai_kontrak", $$v)},expression:"input.tgl_mulai_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Selesai Kontrak","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kontrak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker3","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"state":errors.length > 0 ? false : null},model:{value:(_vm.input.tgl_selesai_kontrak),callback:function ($$v) {_vm.$set(_vm.input, "tgl_selesai_kontrak", $$v)},expression:"input.tgl_selesai_kontrak"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"basicInput"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Keterangan","rows":"3"},model:{value:(_vm.input.keterangan),callback:function ($$v) {_vm.$set(_vm.input, "keterangan", $$v)},expression:"input.keterangan"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_vm._v(" update")],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.close()}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"15"}}),_vm._v(" Batal")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }