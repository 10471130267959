<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-2" size="lg" title="Tambah Paket Sub Kegiatan" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop>
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md="12">
                        <b-row>
                            <b-col md="12">
                                <b-alert variant="primary" show>
                                    <div class="alert-body">
                                        <small>Kede OPD </small>
                                        <h6><strong>{{ detail.kode_dinas }}</strong></h6>
                                        <small>Nama OPD</small>
                                        <h6>{{ detail.nama_dinas }}</h6>
                                        <small>Nama Sub Kegiatan </small>
                                        <h6><strong>{{ detail.kode_sub_kegiatan }} {{ detail.nama_sub_kegiatan }}</strong>
                                        </h6>
                                        <small>Lokasi Sub Kegiatan </small>
                                        <h6><strong>Kecamatan {{ detail.nm_wil }}</strong></h6>
                                        <small>besaran Anggaran </small>
                                        <h4><sup>Rp. </sup><strong> {{ Rp(detail.anggaran) }},-</strong></h4>
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Nomor Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Nomor Kontrak" rules="required">
                                        <b-form-input v-model="input.no_kontrak"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Tanggal Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker" v-model="input.tgl_kontraks" class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-form-group label="Nama Rekanan">
                            <validation-provider #default="{ errors }" name="Rekanan" rules="required">
                                <v-select v-model="input.rekanan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="nama_rekanan" :options="rekanan" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Nilai Kontrak" label-for="basicInput">
                            <validation-provider #default="{ errors }" name="Anggaran " rules="required">
                                <CurrencyInput class="form-control" :state="errors.length > 0 ? false : null"
                                    v-model="input.nilai_kontrak" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="tanggal Mulai Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker2" v-model="input.tgl_mulai_kontrak"
                                            class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Tanggal Selesai Kontrak" label-for="basicInput">
                                    <validation-provider #default="{ errors }" name="Tanggal Kontrak" rules="required">
                                        <b-form-datepicker id="example-datepicker3" v-model="input.tgl_selesai_kontrak"
                                            class="mb-1"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Keterangan" label-for="basicInput">
                            <b-form-textarea id="textarea-default" placeholder="Keterangan" v-model="input.keterangan"
                                rows="3" />
                        </b-form-group>

                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col>
                        <b-button variant="primary" @click.prevent="validationForm"> <feather-icon icon="SaveIcon"
                                size='15' /> update</b-button>
                        <b-button variant="warning" class="ml-1" @click="close()"> <feather-icon icon="XIcon" size='15' />
                            Batal</b-button>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required
} from '@validations'

import {
    BModal,
    BButton,
    VBModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BCardText,
    BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CurrencyInput from './CurrencyInput.vue'

export default {
    components: {
        BButton,
        ValidationProvider,
        ValidationObserver,
        BModal,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormGroup,
        BFormInput,
        BFormSelect,
        CurrencyInput,
        BFormTextarea,
        BCardText,
        BFormDatepicker,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        detail: {},
        rekanan: {}
    },
    data() {
        return {
            required,
            value: '',
        }
    },
    computed: {
        input: {
            get: function () {
                return this.detail;
            },
            set: function (newValue) {
                return this.detail = newValue;
            }
        },
    },
    emits: ['updateData'],
    methods: {
        Rp(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$emit('updateData', this.input);
                    this.close();
                }
            })
        },
        close() {
            this.$root.$emit('bv::hide::modal', 'modal-2')
        }
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>